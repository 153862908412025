import Layout from "@lekoarts/gatsby-theme-jodie/src/components/layout";
import { Container, Image } from "react-bootstrap";
import { Heading } from "theme-ui";

interface AboutMeProps {}

const AboutMe: React.FC<AboutMeProps> = (props) => {
  return (
    <Layout>
      <Container>
        <h1 className="text-3xl font-bold  mt-3"> Mi presento . . .</h1>
        <figure className="md:flex bg-gray-100 rounded-xl p-8 mt-3">
          <div className="pt-6 text-center space-y-4 m-3">
            <p className="text-lg font-medium">
              Mi chiamo Cipriani Michela ho 55 anni e non mi ritengo un’artista,
              ma una donna che trasmette i propri sentimenti e le sue emozioni
              dipingendo.{" "}
            </p>
          </div>
          <img
            className="w-24 h-24 md:w-48 md:h-auto rounded-lg mx-auto"
            src="./profilo1.jpeg"
            alt=""
            width="384"
            height="512"
          />
        </figure>
        <p className="p-2">
          Sono una mamma molto felice e orgogliosa di Alessandro, lavoratrice,
          donna dinamica e versatile, estroversa quanto basta, un po’ selettiva
          e per niente ambiziosa o invidiosa. Adoro viaggiare fare giardinaggio
          e bricolage.
        </p>
        <p className="  p-2">
          Ho iniziato a dipingere fin dall’adolescenza, grazie al dono che mi ha
          trasmesso mio papà, quadri ad olio e tempere con soggetti vari come
          paesaggi, nature morte, stagioni, tutti regalati ad amici o persone
          care.
        </p>
        <p className="  p-2">
          Circa quindici anni fa ho ripreso in mano il pennello e sperimentato
          la pittura con i colori acrilici ed insieme ad altri materiali ho
          iniziato a trasferire su tela immagini e miei stati d’animo che in
          quel momento avevo.
        </p>
        <p className="  p-2">
          Con il tempo ho perfezionato la tecnica acrilico materica e realizzato
          opere di varie misure, principalmente su tela e legno, dipingendo
          soggetti come cavalli, fiori, mare, cuori (i miei soggetti preferiti)
          e recentemente soggetti astratti.
        </p>
        <p className="  p-2">
          Sono del segno dei gemelli: si dice che le donne di questo segno siano
          sveglie e disinvolte, intelligenti e curiose, mentalmente androgine e
          scettiche, ironiche e vivace. Tutto vero ma la caratteristica più
          marcata in me è la curiosità! Devo sapere, conoscere, imparare e
          sperimentare! La pittura in questo mi aiuta perché quando creo un
          quadro adoro usare materiali diversi e tecniche varie: uso un ago e
          cucio del filo di metallo, attacco pezzi di legno, juta, ferro,
          pietre, foglie e qualsiasi cosa calmi la mia vivacità del dipingere.
          Ma sono una persona normale, ho anche momenti in cui la tela rimane
          abbozzata per giorni o anche mesi... e non esce nulla. I quadri che ho
          venduto sono stati visti e piaciuti.
        </p>
        Dipingo anche su commissione ma voglio libertà di espressione.
        <figure className="md:flex bg-gray-100 rounded-xl p-8 mt-3">
          <img
            className="w-24 h-24 md:w-48 md:h-auto rounded-lg mx-auto"
            src="./profilo2.jpeg"
            alt=""
            width="384"
            height="512"
          />
          <div className="pt-6 text-center space-y-4 m-3">
            <blockquote>
              <p className="text-lg font-medium">
                “Voglio dipingere l’amore, vita, speranza, illusioni, ricordi
                felici e delusioni. Cavalli con colori e passioni. Un fiore, una
                margherita o un girasole che segue ovunque il sole! Questo è ciò
                che dipingo".
              </p>
            </blockquote>
            <figcaption className="font-medium">
              <div>Michela Cipriani</div>
            </figcaption>
          </div>
        </figure>
      </Container>
    </Layout>
  );
};

export default AboutMe;
